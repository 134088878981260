import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AnimatePresence, motion } from 'framer-motion';
import SearchIcon from '../svg/SearchIcon';
import { searchedPerformeMain } from '../../../../redux/searched-events/action.js';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
class MainIntro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events_lits: '',
      viewAllevents: false,
      currentPage: 1,
      event_limit: 8,
      isMouseInside: false,
      activeItem: -1,
      inputValueMain: '',
      search_results_main: [],
      isLoading: false,
      inputValueMain_error: false,
    };
  }
  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.performer_search_main !== this.props.performer_search_main) {
      this.setState({
        search_results_main: this.props.performer_search_main,
        isLoading: false,
      });
    }
  }
  componentWillUnmount() {
    // Remove the click event listener when the component unmounts
    document.removeEventListener('click', this.handleDocumentClick);
  }
  handleInputChange = (e) => {
    const value = e.target.value;
    if (e.target) this.setState({ inputValueMain: value, isLoading: true });

    // Filter suggestions based on user input
    if (value !== '') {
      let data = {
        performer_name: value,
        eee: 33,
      };
      this.props.searchedPerformeMain(data, 'performer');
    } else {
      this.setState({
        search_results_main: [],
        isLoading: false,
      });
    }
  };

  searchEvent = () => {
    if (this.state.inputValueMain) {
      this.props.history.push(`/events-results/events/${this.state.inputValueMain}`);
    } else {
      this.setState({
        inputValueMain_error: true,
      });
    }
  };
  seachVenue = () => {
    if (this.state.inputValueMain) {
      this.props.history.push(`/events-results/venue/${this.state.inputValueMain}`);
    } else {
      this.setState({
        inputValueMain_error: true,
      });
    }
  };
  handleClearClick = () => {
    this.setState({ inputValueMain: '', search_results_main: [], isLoading: false });
  };
  onClickEvent = (eventId) => {
    if (eventId) {
      //this.props.history.push(`/event-details/${eventId}`)
      this.props.history.push(`/events-results/performer-tickets/${eventId.replace(/\s+/g, '-')}`);
      this.handleClearClick();
    }
  };
  handleDocumentClick = (event) => {
    const inputElement = event.target.outerHTML.includes('main-intro-search-bar');

    if (!inputElement) {
      // An input element was found within the clicked element

      this.handleClearClick();
    } else {
      // No input element was found within the clicked element
      console.log('No input element found within the clicked element.');
    }
  };
  handleKeyPress = (event) => {
    if (event.key === 'Enter' && this.state.inputValueMain != '') {
      this.props.history.push(`/events-results/performer-tickets/${this.state.inputValueMain.replace(/\s+/g, '-')}`);
    }
  };
  render() {
    const { inputValueMain } = this.state;
    return (
      <div style={{ paddingTop: '80px' }}>
        <div className="container absolute -top-20 left-0 right-0 z-20 flex w-[90%] flex-col gap-2 rounded-lg bg-[#F2F2F2] py-2 p-4 shadow-lg lg:-top-24 test-black lg:w-full lg:max-w-[900px] xl:max-w-[1440px]">
          <label htmlFor="hero-search" className="text-[14px] font-semibold" style={{ margin: '0px' }}>
            Search by Artist, Team, or Event
          </label>
          <div className="flex flex-col items-center gap-2 lg:flex-row lg:gap-4">
            <div className="relative flex w-full grow items-center gap-2 rounded-md bg-[#E0E0E0] px-2 lg:w-fit">
              <label htmlFor="hero-search" className="w-fit">
                <SearchIcon />
              </label>
              <input
                type="text"
                placeholder="Search for Artist, Team, or Performer"
                value={inputValueMain}
                onChange={this.handleInputChange}
                className="w-full rounded-md bg-inherit p-2 focus:outline-none"
                onKeyPress={this.handleKeyPress}
              />
              {inputValueMain && (
                <i className=" fa fa-times cross-main" aria-hidden="true" onClick={this.handleClearClick}></i>
              )}
            </div>

            <button
              onClick={this.searchEvent}
              className="w-full rounded-md bg-main px-8 py-2 text-base font-bold text-[#F2F2F2] lg:w-fit"
            >
              Search
            </button>
          </div>

          <AnimatePresence>
            {this.state.search_results_main.length > 0 ? (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                className="search-results shadow-lg"
              >
                <ul>
                  <li className="suggestion-list-items">
                    <div className="suggestion_box-main">
                      <div className="suggestion_name-main">
                        <h4 className="text-center"> Suggested Results </h4>
                      </div>
                    </div>
                  </li>
                  {this.state.search_results_main.length > 0 &&
                    this.state.search_results_main.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => this.onClickEvent(suggestion.name)}
                        className="suggestion-list-items-main"
                      >
                        <div className="suggestion_box-main">
                          <div className="suggestion_name-main">
                            <h3> {suggestion.name} </h3>
                            {/* <h6 className="search-city-name-main"> {moment(suggestion.date.date).format(" ddd MM/D")} • {suggestion.city.text.name},{suggestion.stateProvince.text.name}  </h6> */}
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </motion.div>
            ) : this.state.isLoading ? (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                className="search-results shadow-lg"
              >
                <ul>
                  <li className="suggestion-list-items">
                    <div className="suggestion_box-main">
                      <div className="suggestion_name-main">
                        <h4 className="text-center"> Suggested Results </h4>
                      </div>
                    </div>
                  </li>
                  <Skeleton count={8} height={35} />
                </ul>
              </motion.div>
            ) : (
              ''
            )}
          </AnimatePresence>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  performer_search_main: state.performer_search_main.performer_search_main,
});
export default withRouter(connect(mapStateToProps, { searchedPerformeMain })(MainIntro));
