import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { motion, AnimatePresence } from 'framer-motion';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import TiktokPixel from 'tiktok-pixel';
import Loader from '../../components/spinner/spinner.jsx';
import { searchedEventByPerformer } from '../../redux/searched-events/action.js';
import Calendar from './ui/Calendar.jsx';
import { MapPin } from 'lucide-react';

import DateRangePicker from './ui/DaterRangePicker.jsx';
import './ui/LocationInput.css';
const API_KEY = 'AIzaSyC7klLiP-8zmTUHi9oWInJuyI7xbZ0CYqo';
class SearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_results: '',
      original_results: '',
      currentPage: 1,
      event_limit: 10,
      keyword_searched: '',
      opneFilterBox: false,
      inputValueFilter: '',
      date: null,
      autocompleteResults: [],
      loadingUserLocation: false,
      startDate: null,
      endDate: null,
      resetKey: 0,
    };
  }

  selectLocation = (place) => {
    this.setState({
      inputValueFilter: place?.structured_formatting?.main_text || place.description,
      autocompleteResults: [],
    });
  };
  resetCalendar = () => {
    this.setState({
      resetKey: this.state.resetKey + 1,
    });
  };
  getUserLocation = () => {
    if (navigator.geolocation) {
      this.setState({ loadingUserLocation: true });
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const response = await fetch(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
            );
            const data = await response.json();
            if (data.address) {
              const city = data.address.city || data.address.town || '';
              const state = data.address.state || '';
              this.setState({
                inputValueFilter: state,
                autocompleteResults: [],
                loadingUserLocation: false,
              });
            }
          } catch (error) {
            console.error('Error fetching location details:', error);
            this.setState({ loadingUserLocation: false });
          }
        },
        (error) => {
          console.error('Error getting location:', error);
          this.setState({ loadingUserLocation: false });
        }
      );
    }
  };

  setDate = (day) => {
    this.setState({ date: day });
  };
  componentDidMount() {
    if (!window.google) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
      script.async = true;
      document.body.appendChild(script);
    }
    TiktokPixel.init('CKUJAQ3C77UDR4OH6180');
    TiktokPixel.track('Events Searches', {
      content_type: 'event name',
      content_name: this.state.keyword_searched.replace(/-/g, ' '),
    });

    window.addEventListener('beforeunload', this.handlePopState);
    this.getEventByPerformer();
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    // Remove the click event listener when the component unmounts
    //document.removeEventListener("click", this.handleDocumentClick);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  handlePopState = (event) => {
    if (this.props.location.pathname.includes('/events-results')) {
      // Reload the window to refresh the home page
      window.location.reload();
    }
  };
  // componentWillUnmount() {
  //   // Remove the popstate event listener when the component unmounts
  //   window.removeEventListener('beforeunload', this.handlePopState);
  // }
  getEventByPerformer() {
    let url_segment = this.props.location.pathname.split('/');

    let keyword_searched = url_segment['3'];

    this.setState({
      keyword_searched: keyword_searched,
    });

    let data = {
      performer_name: keyword_searched.replace(/-/g, ' '),
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };

    this.props.searchedEventByPerformer(data);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searched_events_by_performer !== this.props.searched_events_by_performer) {
      if (this.props.location !== prevProps.location) {
        // Call the API again when the URL updates
        this.getEventByPerformer();
      }

      this.setState({
        search_results: this.props.searched_events_by_performer,
        original_results: this.props.searched_events_by_performer,
      });
    }
  }
  openLocationFilter() {
    this.setState({
      opneFilterBox: !this.state.opneFilterBox,
    });
  }
  handleInputChangeFilter = (e) => {
    const value = e.target.value;
    this.setState({ inputValueFilter: value });

    if (!value) {
      this.setState({ autocompleteResults: [] });
      return;
    }

    if (!this.autocompleteService) {
      this.autocompleteService = new window.google.maps.places.AutocompleteService();
    }

    this.autocompleteService.getPlacePredictions({ input: value, types: ['(cities)'] }, (predictions, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
        this.setState({ autocompleteResults: predictions });
      }
    });
  };

  fliterEventsByLocation = () => {
    const filteredArray = this.state.original_results.filter(
      (item) =>
        item.city.text.name.toLowerCase().includes(this.state.inputValueFilter.toLowerCase()) ||
        item.stateProvince.text.name.toLowerCase().includes(this.state.inputValueFilter.toLowerCase())
    );
    // Set the filtered array in the component's state
    this.setState({ search_results: filteredArray, opneFilterBox: false });
  };
  removeFilter = () => {
    this.setState({
      search_results: this.state.original_results,
      inputValueFilter: '',
      opneFilterBox: false,
    });
  };
  removeDateFilter = () => {
    this.setState({
      startDate: null,
      endDate: null,
    });
    this.resetCalendar();
    setTimeout(() => {
      this.getEventByPerformer();
    }, 500);
  };
  onClickEvent = (eventId) => {
    if (eventId) {
      //this.props.history.push(`/event-details/${eventId}`);
      window.location.href = `/event-details/${eventId}`;
    }
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ opneFilterBox: false });
    }
  };

  handleDateChange = (data) => {
    if (data.startDate && data.endDate) {
      this.setState({
        startDate: moment(data.startDate).format('YYYY-MM-DD'),
        endDate: moment(data.endDate).format('YYYY-MM-DD'),
      });
      setTimeout(() => {
        this.getEventByPerformer();
      }, 500);

      // this.getEventByPerformer();
    } else {
      this.setState({
        startDate: null,
        endDate: null,
      });
    }
  };

  render() {
    const { inputValueFilter, filteredArray, date } = this.state;

    return (
      <>
        <main>
          <Helmet>
            <title>{this.state.keyword_searched.replace(/-/g, ' ')} tickets – Get Yours Now! | Sourced Tickets</title>
            <meta
              name="description"
              content={`${this.state.keyword_searched.replace(
                /-/g,
                ' '
              )} Tickets 2023 - Secure your seats for the ultimate game day experience. Get your  ${this.state.keyword_searched.replace(
                /-/g,
                ' '
              )} tickets now and be part of the action!`}
            />
          </Helmet>
          <section
            id="search"
            className="flex min-h-screen bg-white w-screen flex-col items-start justify-start pt-[60px] text-black"
          >
            {/* Hero */}
            <div className="search-hero flex min-h-[35dvh] w-full flex-col items-center justify-center gap-2 bg-main  lg:min-h-[45dvh]">
              <h1 className="text-[20px] font-light text-highlight-2 lg:text-[24px]">Search Results</h1>
              <p
                className="text-[30px] font-black text-white text-center lg:text-[64px]"
                style={{ maxWidth: '85dvw', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
              >
                {this.state.keyword_searched.replace(/-/g, ' ')}
              </p>
            </div>
            {/* Body */}

            <div className="flex w-full flex-col items-start justify-start gap-10 pb-24">
              <div className="flex w-full items-center justify-center border-b border-gray/35 py-4">
                <div className="container-tw relative flex items-start justify-start">
                  <div className="relative">
                    <h1 className="text-[24px] font-semibold text-highlight-2">Tickets</h1>
                    <motion.div
                      initial={{ width: 0 }}
                      animate={{ width: '100%' }}
                      transition={{ duration: 0.5 }}
                      className="absolute left-0 top-full mt-5 h-1 bg-highlight-2"
                    />
                  </div>
                </div>
              </div>
              <div className="container-tw flex flex-col gap-4 lg:gap-10">
                {/* Filters */}
                <div className="flex flex-col gap-4 lg:flex-row">
                  <div className="flex flex-col gap-2">
                    <label htmlFor="date-picker" className="text-[18px] font-semibold">
                      Date
                    </label>
                    {/* <DateRangePicker onDateChange={this.handleDateChange} /> */}
                    <Calendar onDateChange={this.handleDateChange} resetKey={this.state.resetKey} />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label htmlFor="location-picker" className="text-[18px] font-semibold">
                      Location
                    </label>
                    <div className="location-input" ref={this.setWrapperRef}>
                      <div className="location-display" onClick={() => this.openLocationFilter()}>
                        <MapPin />
                        <span>{inputValueFilter || 'Select Location'}</span>
                      </div>
                      <AnimatePresence>
                        {this.state.opneFilterBox && (
                          <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3 }}
                            className="location-popup"
                          >
                            <input
                              type="text"
                              placeholder="Enter location"
                              value={inputValueFilter}
                              onChange={this.handleInputChangeFilter}
                              className="location-search-input"
                            />
                            <ul className="autocomplete-dropdown">
                              <li className="autocomplete-header" onClick={() => this.getUserLocation()}>
                                <MapPin />
                                {this.state.loadingUserLocation ? 'Loading...' : 'Use my location'}
                              </li>
                              {this.state.autocompleteResults.map((place) => (
                                <li
                                  className="autocompete-item"
                                  key={place.place_id}
                                  onClick={() => this.selectLocation(place)}
                                >
                                  {place.description}
                                </li>
                              ))}
                            </ul>
                            <div className="location-actions">
                              <button className="apply-button" onClick={() => this.fliterEventsByLocation()}>
                                Apply
                              </button>
                              <button className="clear-button" onClick={() => this.removeFilter()}>
                                Clear
                              </button>
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div style={{ height: '33px' }}></div>
                    {/* <DateRangePicker onDateChange={this.handleDateChange} /> */}
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        this.removeFilter();
                        this.removeDateFilter();
                      }}
                      // disabled={this.state.startDate === null || inputValueFilter === ''}
                      className="z-10 w-full whitespace-nowrap rounded-md bg-highlight-2 px-4 py-2 font-black text-white hover:bg-highlight-2/50 lg:w-fit"
                    >
                      Clear Filters
                    </button>
                  </div>
                </div>
                <div className="gap-4- flex flex-row"></div>
                {/* Results */}
                <div className="flex flex-col gap-4">
                  {this.props.in_action_search_by_performer ? (
                    <div className="w-full flex items-center justify-center">
                      <Loader color="#8244DC" />
                    </div>
                  ) : this.state.search_results && this.state.search_results.length > 0 ? (
                    this.state.search_results.map((event) => (
                      <div
                        key={event.id}
                        className="group relative flex w-full flex-col items-center gap-4 rounded-lg bg-[#F6F6F6] p-2 px-4 lg:flex-row hover:bg-highlight-2/10 cursor-pointer transition-all duration-300 lg:gap-0"
                        onClick={() =>
                          this.onClickEvent(
                            `${event.text.name.replace(/\s+/g, '-')}-tickets-${event.city.text.name.replace(
                              /\s+/g,
                              '-'
                            )}-${event.date.date}/${event.id}`
                          )
                        }
                      >
                        <div className="flex w-full grow flex-col gap-2 lg:flex-row lg:gap-0">
                          <article className="flex w-full min-w-fit flex-row items-start justify-center gap-2 border-b border-[#C2C2C2] px-2 py-2 lg:w-1/12 lg:flex-col lg:border-b-0 lg:border-r lg:py-0">
                            <h2 className="text-[16px]  font-normal text-gray">
                              {moment(event.date.date).format('ddd')}
                            </h2>
                            <h2 className="text-[16px] font-extrabold text-highlight-2">
                              {moment(event.date.date).format('MMM Do')}
                            </h2>
                            <h2 className="text-[16px] font-normal text-gray">{event.date.text.time}</h2>
                          </article>
                          <article className="flex w-full grow flex-col items-start justify-center gap-2 px-2 lg:w-1/6 lg:min-w-fit">
                            <h2 className="text-[16px] font-semibold text-gray">{event.text.name}</h2>
                            <h2 className="text-[16px] font-normal text-gray">
                              {event.city.text.name}, {event.stateProvince.text.name}
                            </h2>
                          </article>
                        </div>

                        <button
                          onClick={() =>
                            this.onClickEvent(
                              `${event.text.name.replace(/\s+/g, '-')}-tickets-${event.city.text.name.replace(
                                /\s+/g,
                                '-'
                              )}-${event.date.date}/${event.id}`
                            )
                          }
                          className="z-10 w-full whitespace-nowrap rounded-md bg-highlight-2 px-4 py-2 font-black text-white hover:bg-highlight-2/50 lg:w-fit"
                        >
                          Find Tickets
                        </button>
                      </div>
                    ))
                  ) : (
                    // <div className="event_box no-records">No Records Found</div>
                    <div className="w-full flex items-center justify-center text-gray text-lg">
                      Sorry. There are currently no events matching this criteria.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  searched_events_by_performer: state.searched_events_by_performer.searched_events_by_performer,
  in_action_search_by_performer: state.searched_events_by_performer.in_action_search_by_performer,
});
export default withRouter(connect(mapStateToProps, { searchedEventByPerformer })(SearchResult));

function SaveText() {
  return (
    <div className="unviel_infor">
      <h1 className="first-heading">{this.state.keyword_searched.replace(/-/g, ' ')} Live Events: Your Ticket Guide</h1>
      <p> Get Your Easy and Affordable Seats for {this.state.keyword_searched.replace(/-/g, ' ')} Live Events Today!</p>
      <h2 className="second-heading">
        Secure Your {this.state.keyword_searched.replace(/-/g, ' ')} Tickets with Sourced Tickets
      </h2>
      <p>
        Don't miss the chance to witness <span>{this.state.keyword_searched.replace(/-/g, ' ')}</span> live. Follow
        these steps to secure your tickets:{' '}
      </p>

      <p>
        {' '}
        Explore <span>{this.state.keyword_searched.replace(/-/g, ' ')}</span> upcoming events on Sourced Tickets to find
        an event near you.
      </p>
      <p> Click the "Tickets" button next to your chosen event to proceed.</p>
      <p> Utilize the interactive seating chart to select your desired seats and price range.</p>
      <p> Finalize your order by clicking "Buy" and entering your payment and shipping details.</p>
      <p>
        {' '}
        Rest assured that your <span>{this.state.keyword_searched.replace(/-/g, ' ')}</span> tickets will arrive in time
        for the event.
      </p>

      <p>
        {' '}
        Diverse <span>{this.state.keyword_searched.replace(/-/g, ' ')} </span>
        Ticket Options for You
      </p>
      <p>
        {' '}
        Whether you're on a budget or seeking premium experiences, we offers an variety of
        <span> {this.state.keyword_searched.replace(/-/g, ' ')}</span> ticket options. Enjoy
        <span>{this.state.keyword_searched.replace(/-/g, ' ')}</span> while staying within your budget.
      </p>

      <h3>
        Your Trusted Source for <span>{this.state.keyword_searched.replace(/-/g, ' ')}</span> Tickets{' '}
      </h3>
      <p>
        With us you're guaranteed access to <span>{this.state.keyword_searched.replace(/-/g, ' ')}</span> tickets, even
        for highly anticipated events. Secure your <span>{this.state.keyword_searched.replace(/-/g, ' ')}</span> tickets
        today.
      </p>

      <h3>
        Stay updated on <span>{this.state.keyword_searched.replace(/-/g, ' ')}</span> Events
      </h3>
      <p>
        Stay informed about upcoming <span>{this.state.keyword_searched.replace(/-/g, ' ')}</span> events and discover
        tips to secure the best tickets at the best prices with Sourced Tickets:
      </p>
      <p>
        We keep our prices up-to-date, ensuring you get the most competitive rates. Subscribe to our newsletter to
        receive regular updates on <span>{this.state.keyword_searched.replace(/-/g, ' ')}</span> events, special
        promotions, and exclusive discounts.
      </p>
      <p>
        Follow us on social media for real-time notifications about ticket availability, game schedules, and last-minute
        deals.
      </p>
      <p>
        Customize your preferences to include your favorite sports teams' game times and locations, so you never miss a
        game.
      </p>
      <p>
        Rest easy knowing that your <span>{this.state.keyword_searched.replace(/-/g, ' ')}</span> tickets are backed by
        our 100% Buyer Guarantee.
      </p>
    </div>
  );
}

function Location() {
  return this.state.opneFilterBox ? (
    <div className="location-filter-box">
      <li onClick={() => this.openLocationFilter()}>
        {' '}
        <img src={require('../../assets/images/newimages/c1.png')} alt="sourced" /> location{' '}
      </li>
      <input
        type="text"
        placeholder="Search By Location"
        value={inputValueFilter}
        onChange={this.handleInputChangeFilter}
        className="header-search-bar"
      />
      <div className="filter-apply" onClick={() => this.fliterEventsByLocation()}>
        Apply
      </div>
      <div className="filter-clear" onClick={() => this.removeFilter()}>
        Clear
      </div>
    </div>
  ) : (
    ''
  );
}

function resultsBody() {
  return this.props.in_action_search_by_performer ? (
    <div className="event_box no-records">
      <Loader />
    </div>
  ) : this.state.search_results && this.state.search_results.length > 0 ? (
    this.state.search_results.map((event, key) => (
      <div
        className="event_box"
        onClick={() =>
          this.onClickEvent(
            `${event.text.name.replace(/\s+/g, '-')}-tickets-${event.city.text.name.replace(/\s+/g, '-')}-${
              event.date.date
            }/${event.id}`
          )
        }
      >
        {/* <div className="date">
          <h4>{moment(event.date.date).format('MM/D')} </h4> <p> {moment(event.date.date).format('ddd')} </p>{' '}
        </div> */}
        <div className="Info">
          <h4>{event.text.name} </h4>
          <p>
            {' '}
            {event.city.text.name}, {event.stateProvince.text.name}
          </p>
        </div>
        <div className="date_1">
          <p className="t2">
            <img src={require('../../assets/images/newimages/calender.png')} alt="sourced" />{' '}
            {moment(event.date.date).format('MMM Do')}
          </p>{' '}
        </div>
        <div className="time">
          {' '}
          <p className="t1">
            {' '}
            <img src={require('../../assets/images/newimages/time.png')} alt="sourced" /> {event.date.text.time}
          </p>{' '}
        </div>
        <div className="prices">
          {' '}
          <button>
            <a>
              {/* {" "}
                              {event.pricingInfo
                                ? `From ${event.pricingInfo.lowPrice.text.formatted}`
                                : "N/A"}{" "} */}
              Buy Now
            </a>{' '}
          </button>
        </div>
      </div>
    ))
  ) : (
    // <div className="event_box no-records">No Records Found</div>
    <div className="event_box no-records">Sorry. There are currently no events matching this criteria.</div>
  );
}
