import React from 'react';
import './Calendar.css';
import { ChevronLeftIcon, ChevronRightIcon, Calendar as CalendarIcon } from 'lucide-react';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const threeDaysFromToday = new Date(today);
    threeDaysFromToday.setDate(today.getDate() + 3);

    this.state = {
      currentDate: new Date(),
      selectedStartDate: today,
      selectedEndDate: today,
      isOpen: false,
    };
    this.calendarRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.resetKey !== this.props.resetKey) {
      this.resetCalendar();
    }
  }

  resetCalendar = () => {
    const today = new Date();
    this.setState({
      currentDate: new Date(),
      selectedStartDate: today,
      selectedEndDate: today,
    });
  };

  handleClickOutside = (event) => {
    if (this.calendarRef.current && !this.calendarRef.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  toggleCalendar = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  daysInMonth(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }

  firstDayOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  }

  formatDate(date) {
    return date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
  }

  prevMonth = () => {
    this.setState((prevState) => ({
      currentDate: new Date(prevState.currentDate.getFullYear(), prevState.currentDate.getMonth() - 1),
    }));
  };

  nextMonth = () => {
    this.setState((prevState) => ({
      currentDate: new Date(prevState.currentDate.getFullYear(), prevState.currentDate.getMonth() + 1),
    }));
  };

  selectDate = (day) => {
    const { currentDate, selectedStartDate, selectedEndDate } = this.state;
    const selectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today’s date for accurate comparison

    if (selectedDate < today) {
      return; // Prevent selection of past dates
    }

    if (!selectedStartDate || (selectedStartDate && selectedEndDate)) {
      this.setState(
        {
          selectedStartDate: selectedDate,
          selectedEndDate: null,
        },
        this.notifyParent
      );
    } else if (selectedStartDate && !selectedEndDate) {
      if (selectedDate < selectedStartDate) {
        this.setState(
          {
            selectedStartDate: selectedDate,
          },
          this.notifyParent
        );
      } else {
        this.setState(
          {
            selectedEndDate: selectedDate,
            isOpen: false,
          },
          this.notifyParent
        );
      }
    }
  };

  notifyParent = () => {
    if (this.props.onDateChange) {
      this.props.onDateChange({
        startDate: this.state.selectedStartDate,
        endDate: this.state.selectedEndDate,
      });
    }
  };

  isCurrentDate(day) {
    const today = new Date();
    const { currentDate } = this.state;
    return (
      day === today.getDate() &&
      currentDate.getMonth() === today.getMonth() &&
      currentDate.getFullYear() === today.getFullYear()
    );
  }

  isSelectedDate(day) {
    const { selectedStartDate, selectedEndDate, currentDate } = this.state;
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);

    if (selectedStartDate && selectedEndDate) {
      return date >= selectedStartDate && date <= selectedEndDate;
    }

    return (
      (selectedStartDate && date.getTime() === selectedStartDate.getTime()) ||
      (selectedEndDate && date.getTime() === selectedEndDate.getTime())
    );
  }

  renderDays() {
    const { currentDate } = this.state;
    const daysInMonth = this.daysInMonth(currentDate);
    const firstDay = this.firstDayOfMonth(currentDate);
    const days = [];
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today’s date

    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const selectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);
      const isPastDate = selectedDate < today;
      const isCurrentDay = this.isCurrentDate(i);
      const isSelectedDay = this.isSelectedDate(i);

      days.push(
        <div
          key={i}
          className={`calendar-day ${isCurrentDay ? 'current' : ''} 
                     ${isSelectedDay ? 'selected' : ''} 
                     ${isPastDate ? 'disabled' : ''}`}
          onClick={!isPastDate ? () => this.selectDate(i) : undefined} // Disable past date clicks
        >
          {i}
        </div>
      );
    }

    return days;
  }

  render() {
    const { currentDate, isOpen, selectedStartDate, selectedEndDate } = this.state;

    return (
      <div className="calendar-container" ref={this.calendarRef}>
        <button className="calendar-button" onClick={this.toggleCalendar}>
          <CalendarIcon />
          <h1>
            {moment(selectedStartDate).format('MMM DD')} -{' '}
            {selectedEndDate ? moment(selectedEndDate).format('MMM DD') : 'N/A'}
          </h1>
        </button>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className="calendar"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className="calendar-header">
                <button onClick={this.prevMonth}>
                  <ChevronLeftIcon />
                </button>
                <h2>{this.formatDate(currentDate)}</h2>
                <button onClick={this.nextMonth}>
                  <ChevronRightIcon />
                </button>
              </div>
              <div className="calendar-weekdays">
                <div>Sun</div>
                <div>Mon</div>
                <div>Tue</div>
                <div>Wed</div>
                <div>Thu</div>
                <div>Fri</div>
                <div>Sat</div>
              </div>
              <div className="calendar-days">{this.renderDays()}</div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }
}

export default Calendar;
